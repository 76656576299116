import React from 'react'
import { NavLink } from 'react-router-dom'

const PlantProgress = () => {
    return (
        <div className='container mt-4'>
            <div className='my-3'>
                <form>
                    <div className='row justify-content-around w-100'>
                        <div className="col-lg-3 my-md-2">
                            <input className="form-control" type="text" placeholder='Search' />
                        </div>
                        <div className="col-lg-3 my-md-2">
                            <input className="form-control" type="text" placeholder='Search' />
                        </div>
                        <div className="col-lg-3 my-md-2">
                            <input className="form-control" type="text" placeholder='Search' />
                        </div>
                    </div>
                </form>
            </div>
            <div className='row my-3 justify-content-around'>
                <div className='col-lg-3 shadow p-3 mb-5 bg-white rounded'>
                    <div className='d-flex align-items-center'>
                        <p className='fw-bold'>Kompleks IKS</p>
                    </div>
                    <img src="static/images/kompleks_IKS.jpg" className="w-100" alt="" />
                    <div className='d-flex justify-content-around my-3'>
                        <NavLink to="/plantprogressdetails_mn" className='btn btn-primary'>View Details</NavLink>
                    </div>
                </div>
                <div className='col-lg-3 shadow p-3 mb-5 bg-white rounded'>
                    <div className='d-flex align-items-center'>
                        <p className='fw-bold'>Gerai Ikan</p>
                    </div>
                    <img src="static/images/gerai_ikan.jpg" className="w-100" alt="" />
                    <div className='d-flex justify-content-around my-3'>
                        <NavLink to="/plantprogressdetails_mn" className='btn btn-primary'>View Details</NavLink>
                    </div>
                </div>
                <div className='col-lg-3 shadow p-3 mb-5 bg-white rounded'>
                    <div className='d-flex align-items-center'>
                        <p className='fw-bold'>Akung Cafe</p>
                    </div>
                    <img src="static/images/cafe.jpg" className="w-100" alt="" />
                    <div className='d-flex justify-content-around my-3'>
                        <NavLink to="/plantprogressdetails_mn" className='btn btn-primary'>View Details</NavLink>
                    </div>
                </div>
            </div>
            <div className='row my-3 justify-content-around'>
                <div className='col-lg-3 shadow p-3 mb-5 bg-white rounded'>
                    <div className='d-flex align-items-center'>
                        <p className='fw-bold'>Jeti Serusop</p>
                    </div>
                    <img src="static/images/jeti_kampung_serusop.jpg" className="w-100" alt="" />
                    <div className='d-flex justify-content-around my-3'>
                        <NavLink to="/plantprogressdetails_mn" className='btn btn-primary'>View Details</NavLink>
                    </div>
                </div>
                <div className='col-lg-3 shadow p-3 mb-5 bg-white rounded'>
                    <div className='d-flex align-items-center'>
                        <p className='fw-bold'>Resort Eco Fishing</p>
                    </div>
                    <img src="static/images/resort_eko.jpg" className="w-100" alt="" />
                    <div className='d-flex justify-content-around my-3'>
                        <NavLink to="/plantprogressdetails_mn" className='btn btn-primary'>View Details</NavLink>
                    </div>
                </div>
                <div className='col-lg-3 shadow p-3 mb-5 bg-white rounded'>
                    <div className='d-flex align-items-center'>
                        <p className='fw-bold'>Pondok Polis</p>
                    </div>
                    <img src="static/images/pondok_polis_serusup.jpg" className="w-100" alt="" />
                    <div className='d-flex justify-content-around my-3'>
                        <NavLink to="/plantprogressdetails_mn" className='btn btn-primary'>View Details</NavLink>
                    </div>
                </div>
            </div>
            <div className='row my-3 justify-content-around'>
                <div className='col-lg-3 shadow p-3 mb-5 bg-white rounded'>
                    <div className='d-flex align-items-center'>
                        <p className='fw-bold'>SK Serusop</p>
                    </div>
                    <img src="static/images/sk_serosup.jpg" className="w-100" alt="" />
                    <div className='d-flex justify-content-around my-3'>
                        <NavLink to="/plantprogressdetails_mn" className='btn btn-primary'>View Details</NavLink>
                    </div>
                </div>
                <div className='col-lg-3 shadow p-3 mb-5 bg-white rounded'>
                    <div className='d-flex align-items-center'>
                        <p className='fw-bold'>Kilang Pemprosesan</p>
                    </div>
                    <img src="static/images/kilang.jpg" className="w-100" alt="" />
                    <div className='d-flex justify-content-around my-3'>
                        <NavLink to="/plantprogressdetails_mn" className='btn btn-primary'>View Details</NavLink>
                    </div>
                </div>
                <div className='col-lg-3 shadow p-3 mb-5 bg-white rounded'>
                    <div className='d-flex align-items-center'>
                        <p className='fw-bold'>Bayu Aqua Culture</p>
                    </div>
                    <img src="static/images/aqua.jpg" className="w-100" alt="" />
                    <div className='d-flex justify-content-around my-3'>
                        <NavLink to="/plantprogressdetails_mn" className='btn btn-primary'>View Details</NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PlantProgress