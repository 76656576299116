import React, { Fragment, useState, useEffect } from 'react'
import { Link, NavLink, useHistory, useNavigate} from 'react-router-dom'
import { logout } from '../actions/auth'
import { connect } from 'react-redux'
import './components.css'
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as IoIcons5 from "react-icons/io5";
import * as RiIcons from "react-icons/ri";
import * as HiIcons from "react-icons/hi";
import * as VscIcons from 'react-icons/vsc'
import { BsFillArrowDownCircleFill } from "react-icons/bs";
import { IconContext } from 'react-icons';

const Navbar = ({ logout, isAuthenticated, role, activeSideNav, currPage, loadState }) => {

  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [sidebar, setSidebar] = useState(true);
  const [activeMenu, setActiveMenu] = useState(true)
  const [currentPage, setCurrentPage] = useState()
  const [dropAcc, setDropAcc] = useState(false)
  const [hrefLoc, setHrefLoc] = useState(window.location.href)

  const showSidebar = (event) => {
    setSidebar(!sidebar);
    activeSideNav(!sidebar)
  }
  const showDropAcc = () => setDropAcc(!dropAcc)

  // useEffect(() => {
  //   setHrefLoc(window.location.href)
  // }, [hrefLoc])
  
  const handleLogout = (e) => {
    e.preventDefault()
    // setLoading(true)
    loadState(true)
    setTimeout(() => {
      logout()
      navigate("/")
      setLoading(false)
      loadState(false)
    }, 4000);
  }

  const onClickMenu = (item) => {
    setActiveMenu(!activeMenu)
    setCurrentPage(item)
    currPage(item)
  }

  const menuItem = [
    {
      path: "/home_gn",
      name: "Home",
      icon: <FaIcons.FaChartPie />,
      cName: 'nav-text'
    },
    {
      path: "/summary_view_gn",
      name: "Summary",
      cName: 'nav-text',
      icon: <VscIcons.VscGraph />,
    },
    {
      path: "/farmer_gn",
      name: "Petrol Station",
      cName: 'nav-text',
      icon: <IoIcons5.IoPersonCircleSharp />,
    },
    {
      path: "/inventory_gn",
      name: "Inventory",
      cName: 'nav-text',
      icon: <RiIcons.RiFileList3Fill />,
    },
    {
      path: "/plantprogress_gn",
      name: "Area Progress",
      cName: 'nav-text',
      icon: <RiIcons.RiPlantFill />,
    },
    {
      path: "/repository_gn",
      name: "Repository",
      cName: 'nav-text',
      icon: <RiIcons.RiArticleFill />,
    },
    {
      path: "/summary_gn",
      name: "Analysis",
      cName: 'nav-text',
      icon: <HiIcons.HiPresentationChartBar />,
    },
    
  ]

  const menuItemManager = [
    {
      path: "/home_mn",
      name: "Home",
      icon: <FaIcons.FaChartPie />,
      cName: 'nav-text'
    },
    {
      path: "/summary_view_mn",
      name: "Summary",
      cName: 'nav-text',
      icon: <VscIcons.VscGraph />,
    },
    {
      path: "/farmer_mn",
      name: "Petrol Station",
      cName: 'nav-text',
      icon: <IoIcons5.IoPersonCircleSharp />,
    },
    {
      path: "/inventory_mn",
      name: "Inventory",
      cName: 'nav-text',
      icon: <RiIcons.RiFileList3Fill />,
    },
    {
      path: "/plantprogress_mn",
      name: "Area Progress",
      cName: 'nav-text',
      icon: <RiIcons.RiPlantFill />,
    },
    {
      path: "/repository_mn",
      name: "Repository",
      cName: 'nav-text',
      icon: <RiIcons.RiArticleFill />,
    },
    {
      path: "/report_mn",
      name: "Report",
      cName: 'nav-text',
      icon: <IoIcons5.IoBarChartSharp />,
    },
    {
      path: "/summary_mn",
      name: "Analysis",
      cName: 'nav-text',
      icon: <HiIcons.HiPresentationChartBar />,
    },
  ]

  const authLinks = () => (
    <>
      <IconContext.Provider value={{ color: '#1B59F8' }}>
        <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
          <ul className='nav-menu-items'>
            <li className='navbar-toggle d-flex justify-content-center align-items-center' onClick={showSidebar}>
              <span to='#' className='menu-bars text-center'>
                <FaIcons.FaBars />
              </span>
            </li>
            <li className="w-100 d-flex justify-content-center my-2">
              <img src="/static/images/pt-logo.png" alt="" width="120" className={sidebar ? null : "logo-img"} />
            </li>
            {menuItem.map((item, index) => {
              return (
                <li key={index} id='sidebar' className={sidebar ? `${item.cName} text-primary d-flex justify-content-center` : `${item.cName} text-light close-sidebar-icon`}>
                  <NavLink to={item.path} onClick={() => onClickMenu(item.name)}>
                    {item.icon}
                    <span style={{color: '#000000'}} className={sidebar ? 'menu-item-text' : 'text-none'}>{item.name}</span>
                  </NavLink>
                </li>
              );
            })}
            <li className='nav-text text-light'>
              <Link to='/' onClick={e => handleLogout(e)}>
                <AiIcons.AiOutlineClose />
                <span className={sidebar ? 'menu-item-text' : 'text-none'}>Logout</span>
              </Link>
            </li>
            {/* <li className='footer-img-nav pb-2'>
              <div className='d-flex justify-content-center'>
                <img src="static/images/Picture1.png" alt="" className='w-25' />
                <img src="static/images/bernas-nasioanal-logo-EB1FE7BD5C-seeklogo.com.png" alt="" className='w-25' />
              </div>
            </li> */}
          </ul>
        </nav>
      </IconContext.Provider>
    </>
  )

  const authLinksManager = () => (
    <>
      <IconContext.Provider value={{ color: '#1B59F8' }}>
        <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
          <ul className='nav-menu-items'>
            <li onClick={showSidebar} className="d-flex justify-content-center my-3">
                <FaIcons.FaBars size={30}/>
            </li>
            <li className="w-100 d-flex justify-content-center my-2">
              <img src="/static/images/pt-logo.png" alt="" width="120" className={sidebar ? null : "logo-img"} />
            </li>
            {menuItemManager.map((item, index) => {
              return (
                <li key={index} id='sidebar' style={{color: '#1B59F8'}} className={sidebar ? `${item.cName} d-flex justify-content-center` : `${item.cName} close-sidebar-icon`}>
                  <NavLink to={item.path} onClick={() => onClickMenu(item.name)}>
                    {item.icon}
                    <span style={{color: '#000000'}} className={sidebar ? 'menu-item-text' : 'text-none'}>{item.name}</span>
                  </NavLink>
                </li>
              );
            })}
            <li className='nav-text text-light'>
              <Link to='/' onClick={e => handleLogout(e)}>
                <AiIcons.AiOutlineClose />
                <span style={{color: '#000000'}} className={sidebar ? 'menu-item-text' : 'text-none'}>Logout</span>
              </Link>
            </li>
            {/* <li className='footer-img-nav pb-2'>
              <div className='d-flex justify-content-center'>
                <img src="static/images/Picture1.png" alt="" className='w-25' />
                <img src="static/images/bernas-nasioanal-logo-EB1FE7BD5C-seeklogo.com.png" alt="" className='w-25' />
              </div>
            </li> */}
          </ul>
        </nav>
      </IconContext.Provider>
    </>
  )

  return (
    <>
      {isAuthenticated && role === "Clerk" ? authLinks() : isAuthenticated && role == "Manager" ? authLinksManager() : null}
    </>
  )
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  role: state.profile.role
})

export default connect(mapStateToProps, { logout })(Navbar)