import React, { Fragment, useState, useEffect } from 'react'
import { Link, NavLink, useHistory, useNavigate } from 'react-router-dom'
import { logout } from '../actions/auth'
import { connect } from 'react-redux'
import './components.css'
import * as AiIcons from 'react-icons/ai';
import { AiFillCaretDown } from "react-icons/ai"
import { IconContext } from 'react-icons';

const Navbar = ({ logout, isAuthenticated, role, email, name, currPage, loadState }) => {
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [sidebar, setSidebar] = useState(true);
    const [dropAcc, setDropAcc] = useState(false)
    const [currentPage, setCurrentPage] = useState()
    const [hrefLoc, setHrefLoc] = useState(window.location.href)

    const showSidebar = () => setSidebar(!sidebar);
    const showDropAcc = () => setDropAcc(!dropAcc)

    useEffect(() => {
        setHrefLoc(window.location.href)
    }, [hrefLoc])

    const handleLogout = (e) => {
        e.preventDefault()
        // setLoading(true)
        loadState(true)
        setTimeout(() => {
            logout()
            navigate("/")
            setLoading(false)
            loadState(false)
        }, 4000);
    }

    const authLinks = () => (
        <>
            <IconContext.Provider value={{ color: '#fff' }}>
                <div className='navbar d-flex justify-content-around border-bottom border-4 w-100'>
                    <div className='mx-5-lg d-flex justify-content-between w-100'>
                        <div className='d-flex justify-content-center align-items-center'>
                            <h2 className='fw-bold'>{currPage === undefined ? "Home" : `${currPage}`}</h2>
                        </div>
                        <div className='d-flex justify-content-around account-drop p-1'>
                            <div className='d-flex flex-column' onClick={showDropAcc}>
                                <div className='d-flex justify-content-around '>
                                    <div className='d-flex align-items-center justify-content-center w-25 border-2 border-end'>
                                        <AiIcons.AiFillBell className='w-100' size={30} color='#808080' />
                                    </div>
                                    <div className='d-flex justify-content-center align-items-center w-50'>
                                        <img src="static/logo192.png" alt="" srcset="" width='20%' />
                                        <div className="dropdown">
                                            <div className="dropdown-toggle profile-topbar" href="#" data-bs-toggle="dropdown" aria-expanded="false" style={{ textDecoration: 'none', color: '#000000' }}>
                                                <span>{name}</span>
                                                <br />
                                                <span>{email}</span>
                                            </div>
                                            <ul className="dropdown-menu">
                                                {/* <li><a className="dropdown-item" href="#">Action</a></li>
                                                <li><a className="dropdown-item" href="#">Another action</a></li>
                                                <li><a className="dropdown-item" href="#">Something else here</a></li> */}
                                                <li className='dropdown-item' style={{textDecoration: 'none', color: '#000000'}}>
                                                    <Link to='/account' style={{textDecoration: 'none', color: '#000000'}}>
                                                        My Profile
                                                    </Link>
                                                </li>
                                                <li className='dropdown-item' style={{textDecoration: 'none', color: '#000000'}}>
                                                    <Link to='/' onClick={e => handleLogout(e)} style={{textDecoration: 'none', color: '#000000'}}>
                                                        Logout
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                                {/* <div className={dropAcc === true ? "shadow-lg bg-white rounded dropdown-account-active d-flex justify-content-center px-3 py-1" : "dropdown-account"}>
                                    <ul className='dropdown-account-drop-ul'>
                                        <li className='dropdown-account-drop'>
                                            <Link to='/account'>
                                                My Profile
                                            </Link>
                                        </li>
                                        <li className='dropdown-account-drop'>
                                            <Link to='/' onClick={e => handleLogout(e)}>
                                                Logout
                                            </Link>
                                        </li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </IconContext.Provider>
        </>
    )

    return (
        <>
            {isAuthenticated ? authLinks() : null}
        </>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    role: state.profile.role,
    email: state.profile.email,
    name: state.profile.name
})

export default connect(mapStateToProps, { logout })(Navbar)