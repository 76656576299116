import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const Inventory = () => {
    return (
        <div className='container mt-4'>
            <div className='shadow p-3 mb-5 bg-white rounded'>
                <h5>Inventory</h5>
                <div className='row justify-content-around p-2'>
                    <div className='col-lg mx-2 shadow-sm p-3 mb-5 rounded bg-warning'>
                        <p className='text-light'>Low Stock</p>
                        <div>
                            <h4 className='text-light'>10</h4>
                        </div>
                    </div>
                    <div className='col-lg mx-2  shadow-sm p-3 mb-5 rounded bg-danger'>
                        <p className='text-light'>Out Of Stock</p>
                        <div>
                            <h4 className='text-light'>10</h4>
                        </div>
                    </div>
                    <div className='col-lg mx-2  shadow-sm p-3 mb-5 rounded bg-secondary'>
                        <p className='text-light'>Zero Stock</p>
                        <div>
                            <h4 className='text-light'>10</h4>
                        </div>
                    </div>
                    <div className='col-lg mx-2  shadow-sm p-3 mb-5 rounded bg-dark'>
                        <p className='text-light'>Void Stock</p>
                        <div>
                            <h4 className='text-light'>10</h4>
                        </div>
                    </div>
                    <div className='col-lg mx-2  shadow-sm p-3 mb-5 rounded bg-success'>
                        <p className='text-light'>Total Product</p>
                        <div>
                            <h4 className='text-light'>10</h4>
                        </div>
                    </div>
                </div>
            </div>

            <Tabs
                defaultActiveKey="all_invoice"
                id="uncontrolled-tab-example"
                className="mb-3"
            >
                <Tab eventKey="open_invoice" title="Open Invoice">
                    <div>
                        <div className='row p-2' style={{backgroundColor: '#ececec', color: '#8E8E8E', fontSize: '0.8em', border: 'solid 1px black', borderRadius: '1em'}}>
                            <div className='col'>
                                INVOICE ID
                            </div>
                            <div className='col'>
                                START DATE
                            </div>
                            <div className='col'>
                                END DATE
                            </div>
                            <div className='col'>
                                TOTAL ACTIVITY
                            </div>
                            <div className='col'>
                                INVOICE AMOUNT
                            </div>
                            <div className='col'>
                                STATUS
                            </div>
                            <div className='col'>
                                DETAILS
                            </div>
                        </div>
                        <div className='row p-2' style={{ color: '#1F2B6D', fontSize: '0.8em', fontWeight: 'bold' }}>
                            <div className='col'>
                                #12995
                            </div>
                            <div className='col'>
                                18/22/23
                            </div>
                            <div className='col'>
                                22/22/23
                            </div>
                            <div className='col'>
                                2 activity
                            </div>
                            <div className='col'>
                                RM 400.00
                            </div>
                            <div className='col'>
                                Paid
                            </div>
                            <div className='col d-flex'>
                                <div>
                                    open
                                </div>
                                <div>
                                    close
                                </div>
                            </div>
                        </div>
                        <div className='row p-2' style={{ color: '#1F2B6D', fontSize: '0.8em', fontWeight: 'bold' }}>
                            <div className='col'>
                                #12995
                            </div>
                            <div className='col'>
                                18/22/23
                            </div>
                            <div className='col'>
                                22/22/23
                            </div>
                            <div className='col'>
                                2 activity
                            </div>
                            <div className='col'>
                                RM 400.00
                            </div>
                            <div className='col'>
                                Paid
                            </div>
                            <div className='col d-flex'>
                                <div>
                                    open
                                </div>
                                <div>
                                    close
                                </div>
                            </div>
                        </div>
                        <div className='row p-2' style={{ color: '#1F2B6D', fontSize: '0.8em', fontWeight: 'bold' }}>
                            <div className='col'>
                                #12995
                            </div>
                            <div className='col'>
                                18/22/23
                            </div>
                            <div className='col'>
                                22/22/23
                            </div>
                            <div className='col'>
                                2 activity
                            </div>
                            <div className='col'>
                                RM 400.00
                            </div>
                            <div className='col'>
                                Paid
                            </div>
                            <div className='col d-flex'>
                                <div>
                                    open
                                </div>
                                <div>
                                    close
                                </div>
                            </div>
                        </div>
                        <div className='row p-2' style={{ color: '#1F2B6D', fontSize: '0.8em', fontWeight: 'bold' }}>
                            <div className='col'>
                                #12995
                            </div>
                            <div className='col'>
                                18/22/23
                            </div>
                            <div className='col'>
                                22/22/23
                            </div>
                            <div className='col'>
                                2 activity
                            </div>
                            <div className='col'>
                                RM 400.00
                            </div>
                            <div className='col'>
                                Paid
                            </div>
                            <div className='col d-flex'>
                                <div>
                                    open
                                </div>
                                <div>
                                    close
                                </div>
                            </div>
                        </div>
                        <div className='row p-2' style={{ color: '#1F2B6D', fontSize: '0.8em', fontWeight: 'bold' }}>
                            <div className='col'>
                                #12995
                            </div>
                            <div className='col'>
                                18/22/23
                            </div>
                            <div className='col'>
                                22/22/23
                            </div>
                            <div className='col'>
                                2 activity
                            </div>
                            <div className='col'>
                                RM 400.00
                            </div>
                            <div className='col'>
                                Paid
                            </div>
                            <div className='col d-flex'>
                                <div>
                                    open
                                </div>
                                <div>
                                    close
                                </div>
                            </div>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="past_invoice" title="Past Invoice">
                    <div>
                        <div className='row bg-secondary p-2 text-light'>
                            <div className='col'>
                                INVOICE ID
                            </div>
                            <div className='col'>
                                START DATE
                            </div>
                            <div className='col'>
                                END DATE
                            </div>
                            <div className='col'>
                                TOTAL ACTIVITY
                            </div>
                            <div className='col'>
                                INVOICE AMOUNT
                            </div>
                            <div className='col'>
                                STATUS
                            </div>
                            <div className='col'>
                                DETAILS
                            </div>
                        </div>
                        <div className='row p-2' style={{ color: '#1F2B6D', fontSize: '0.8em', fontWeight: 'bold' }}>
                            <div className='col'>
                                #12995
                            </div>
                            <div className='col'>
                                18/22/23
                            </div>
                            <div className='col'>
                                22/22/23
                            </div>
                            <div className='col'>
                                2 activity
                            </div>
                            <div className='col'>
                                RM 400.00
                            </div>
                            <div className='col'>
                                Paid
                            </div>
                            <div className='col d-flex'>
                                <div>
                                    open
                                </div>
                                <div>
                                    close
                                </div>
                            </div>
                        </div>
                        <div className='row p-2' style={{ color: '#1F2B6D', fontSize: '0.8em', fontWeight: 'bold' }}>
                            <div className='col'>
                                #12995
                            </div>
                            <div className='col'>
                                18/22/23
                            </div>
                            <div className='col'>
                                22/22/23
                            </div>
                            <div className='col'>
                                2 activity
                            </div>
                            <div className='col'>
                                RM 400.00
                            </div>
                            <div className='col'>
                                Paid
                            </div>
                            <div className='col d-flex'>
                                <div>
                                    open
                                </div>
                                <div>
                                    close
                                </div>
                            </div>
                        </div>
                        <div className='row p-2' style={{ color: '#1F2B6D', fontSize: '0.8em', fontWeight: 'bold' }}>
                            <div className='col'>
                                #12995
                            </div>
                            <div className='col'>
                                18/22/23
                            </div>
                            <div className='col'>
                                22/22/23
                            </div>
                            <div className='col'>
                                2 activity
                            </div>
                            <div className='col'>
                                RM 400.00
                            </div>
                            <div className='col'>
                                Paid
                            </div>
                            <div className='col d-flex'>
                                <div>
                                    open
                                </div>
                                <div>
                                    close
                                </div>
                            </div>
                        </div>
                        <div className='row p-2' style={{ color: '#1F2B6D', fontSize: '0.8em', fontWeight: 'bold' }}>
                            <div className='col'>
                                #12995
                            </div>
                            <div className='col'>
                                18/22/23
                            </div>
                            <div className='col'>
                                22/22/23
                            </div>
                            <div className='col'>
                                2 activity
                            </div>
                            <div className='col'>
                                RM 400.00
                            </div>
                            <div className='col'>
                                Paid
                            </div>
                            <div className='col d-flex'>
                                <div>
                                    open
                                </div>
                                <div>
                                    close
                                </div>
                            </div>
                        </div>
                        <div className='row p-2' style={{ color: '#1F2B6D', fontSize: '0.8em', fontWeight: 'bold' }}>
                            <div className='col'>
                                #12995
                            </div>
                            <div className='col'>
                                18/22/23
                            </div>
                            <div className='col'>
                                22/22/23
                            </div>
                            <div className='col'>
                                2 activity
                            </div>
                            <div className='col'>
                                RM 400.00
                            </div>
                            <div className='col'>
                                Paid
                            </div>
                            <div className='col d-flex'>
                                <div>
                                    open
                                </div>
                                <div>
                                    close
                                </div>
                            </div>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="all_invoice" title="All Invoice">
                    <div>
                        <div className='row bg-secondary p-2 text-light'>
                            <div className='col'>
                                INVOICE ID
                            </div>
                            <div className='col'>
                                START DATE
                            </div>
                            <div className='col'>
                                END DATE
                            </div>
                            <div className='col'>
                                TOTAL ACTIVITY
                            </div>
                            <div className='col'>
                                INVOICE AMOUNT
                            </div>
                            <div className='col'>
                                STATUS
                            </div>
                            <div className='col'>
                                DETAILS
                            </div>
                        </div>
                        <div className='row p-2' style={{ color: '#1F2B6D', fontSize: '0.8em', fontWeight: 'bold' }}>
                            <div className='col'>
                                #12995
                            </div>
                            <div className='col'>
                                18/22/23
                            </div>
                            <div className='col'>
                                22/22/23
                            </div>
                            <div className='col'>
                                2 activity
                            </div>
                            <div className='col'>
                                RM 400.00
                            </div>
                            <div className='col'>
                                Paid
                            </div>
                            <div className='col d-flex'>
                                <div>
                                    open
                                </div>
                                <div>
                                    close
                                </div>
                            </div>
                        </div>
                        <div className='row p-2' style={{ color: '#1F2B6D', fontSize: '0.8em', fontWeight: 'bold' }}>
                            <div className='col'>
                                #12995
                            </div>
                            <div className='col'>
                                18/22/23
                            </div>
                            <div className='col'>
                                22/22/23
                            </div>
                            <div className='col'>
                                2 activity
                            </div>
                            <div className='col'>
                                RM 400.00
                            </div>
                            <div className='col'>
                                Paid
                            </div>
                            <div className='col d-flex'>
                                <div>
                                    open
                                </div>
                                <div>
                                    close
                                </div>
                            </div>
                        </div>
                        <div className='row p-2' style={{ color: '#1F2B6D', fontSize: '0.8em', fontWeight: 'bold' }}>
                            <div className='col'>
                                #12995
                            </div>
                            <div className='col'>
                                18/22/23
                            </div>
                            <div className='col'>
                                22/22/23
                            </div>
                            <div className='col'>
                                2 activity
                            </div>
                            <div className='col'>
                                RM 400.00
                            </div>
                            <div className='col'>
                                Paid
                            </div>
                            <div className='col d-flex'>
                                <div>
                                    open
                                </div>
                                <div>
                                    close
                                </div>
                            </div>
                        </div>
                        <div className='row p-2' style={{ color: '#1F2B6D', fontSize: '0.8em', fontWeight: 'bold' }}>
                            <div className='col'>
                                #12995
                            </div>
                            <div className='col'>
                                18/22/23
                            </div>
                            <div className='col'>
                                22/22/23
                            </div>
                            <div className='col'>
                                2 activity
                            </div>
                            <div className='col'>
                                RM 400.00
                            </div>
                            <div className='col'>
                                Paid
                            </div>
                            <div className='col d-flex'>
                                <div>
                                    open
                                </div>
                                <div>
                                    close
                                </div>
                            </div>
                        </div>
                        <div className='row p-2' style={{ color: '#1F2B6D', fontSize: '0.8em', fontWeight: 'bold' }}>
                            <div className='col'>
                                #12995
                            </div>
                            <div className='col'>
                                18/22/23
                            </div>
                            <div className='col'>
                                22/22/23
                            </div>
                            <div className='col'>
                                2 activity
                            </div>
                            <div className='col'>
                                RM 400.00
                            </div>
                            <div className='col'>
                                Paid
                            </div>
                            <div className='col d-flex'>
                                <div>
                                    open
                                </div>
                                <div>
                                    close
                                </div>
                            </div>
                        </div>

                    </div>
                </Tab>
            </Tabs>

        </div>
    )
}

export default Inventory