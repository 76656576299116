import React, { useEffect, useState } from "react";
import Navbar from '../components/Navbar'
import Topbar from "../components/Topbar";
import { connect } from 'react-redux'
import { checkAuthenticated } from '../actions/auth'
import { load_user } from "../actions/profile";

const Layout = (props) => {
    const [activeNav, setActiveNav] = useState(true)
    const [currentPage, setCurrentPage] = useState()
    const [loading, setLoading] = useState()

    useEffect(() => {
        props.checkAuthenticated();
        props.load_user()
    }, [])

    const handleSideNavState = (newState) => {
        setActiveNav(newState)
    }

    const handleCurrentPage = (currPage) => {
        setCurrentPage(currPage)
    }

    const handleLoading = (loadState) => {
        setLoading(loadState)
    }

    console.log(currentPage)

    return (
        <>
            {
                loading ? (
                    <div className="text-center loading-screen">
                        <div className='spinner-main-custom' >
                            <div className="spinner-border" style={{ width: "5rem", height: "5rem" }} role="status">
                                <span className="sr-only"></span>

                            </div>
                        </div>
                        <div style={{color: '#ffffff', fontWeight: 'bold'}}>
                            Logging you out now...
                        </div>
                    </div>
                ) : null
            }
            <div className="w-100 d-flex">
                <div style={activeNav ? { position: "fixed", width: '11%', transition: 'width .2s' } : { position: "fixed", width: '4%', transition: 'width .2s' }}>
                    <Navbar activeSideNav={handleSideNavState} currPage={handleCurrentPage} loadState={handleLoading} />
                </div>
                <div style={activeNav ? { marginLeft: '11%', width: '89%', transition: 'margin .2s' } : { marginLeft: '4%', width: '96%', transition: 'margin .2s' }}>
                    <Topbar currPage={currentPage} loadState={handleLoading} />
                    {props.children}
                </div>
            </div>
        </>

    )

};

export default connect(null, { checkAuthenticated, load_user })(Layout);