import React from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'

const FarmerDetails = ({ role }) => {

    console.log(role)

    return (

        <div className='container mt-4'>
            <div className='my-3'>
                <form>
                    <div className='row justify-content-around w-100'>
                        <div className="col-lg-3 my-md-2">
                            <input className="form-control" type="text" placeholder='Search' />
                        </div>
                        <div className="col-lg-3 my-md-2">
                            <input className="form-control" type="text" placeholder='Search' />
                        </div>
                        <div className="col-lg-3 my-md-2">
                            <input className="form-control" type="text" placeholder='Search' />
                        </div>
                    </div>
                </form>
            </div>

            <div className='my-3'>
                <div className='justify-content-center d-flex px-2 w-50 container shadow p-3 mb-5 bg-white rounded'>
                    <div className='w-25 h-25 d-flex align-items-center border border-2 rounded-circle'>
                        <img src="static/images/pt.jpg" className="w-100" alt="" />
                    </div>
                    <div className='px-2 d-flex flex-column'>
                        <span>Name: Petrol Station 1</span>
                        <span>ID Number: 0000001</span>
                        <span>Location: A</span>

                    </div>
                    <div className='px-2 d-flex align-items-center'>
                        {
                            role === 'Manager' ? (
                                <NavLink to='/farmersec_mn' className='btn btn-primary'>
                                    View Detail
                                </NavLink>
                            ) : (
                                <NavLink to='/farmersec_gn' className='btn btn-primary'>
                                    View Detail
                                </NavLink>
                            )
                        }
                    </div>
                </div>

                <div className='justify-content-center d-flex px-2 w-50 container shadow p-3 mb-5 bg-white rounded'>
                    <div className='w-25 h-25 d-flex align-items-center border border-2 rounded-circle'>
                        <img src="static/images/pt.jpg" className="w-100" alt="" />
                    </div>
                    <div className='px-2 d-flex flex-column'>
                        <span>Name: Petrol Station 2</span>
                        <span>ID Number: 0000002</span>
                        <span>Location: B</span>

                    </div>
                    <div className='px-2 d-flex align-items-center'>
                        {
                            role === 'Manager' ? (
                                <NavLink to='/farmersec_mn' className='btn btn-primary'>
                                    View Detail
                                </NavLink>
                            ) : (
                                <NavLink to='/farmersec_gn' className='btn btn-primary'>
                                    View Detail
                                </NavLink>
                            )
                        }

                    </div>
                </div>

                <div className='justify-content-center d-flex px-2 w-50 container shadow p-3 mb-5 bg-white rounded'>
                    <div className='w-25 h-25 d-flex align-items-center border border-2 rounded-circle'>
                        <img src="static/images/pt.jpg" className="w-100" alt="" />
                    </div>
                    <div className='px-2 d-flex flex-column'>
                        <span>Name: Petrol Station 3</span>
                        <span>ID Number: 0000003</span>
                        <span>Location: C</span>

                    </div>
                    <div className='px-2 d-flex align-items-center'>
                        {
                            role === 'Manager' ? (
                                <NavLink to='/farmersec_mn' className='btn btn-primary'>
                                    View Detail
                                </NavLink>
                            ) : (
                                <NavLink to='/farmersec_gn' className='btn btn-primary'>
                                    View Detail
                                </NavLink>
                            )
                        }
                    </div>
                </div>

                <div className='justify-content-center d-flex px-2 w-50 container shadow p-3 mb-5 bg-white rounded'>
                    <div className='w-25 h-25 d-flex align-items-center border border-2 rounded-circle'>
                        <img src="static/images/pt.jpg" className="w-100" alt="" />
                    </div>
                    <div className='px-2 d-flex flex-column'>
                        <span>Name: Petrol Station 4</span>
                        <span>ID Number: 0000004</span>
                        <span>Location: D</span>

                    </div>
                    <div className='px-2 d-flex align-items-center'>
                        {
                            role === 'Manager' ? (
                                <NavLink to='/farmersec_mn' className='btn btn-primary'>
                                    View Detail
                                </NavLink>
                            ) : (
                                <NavLink to='/farmersec_gn' className='btn btn-primary'>
                                    View Detail
                                </NavLink>
                            )
                        }
                    </div>
                </div>

                <div className='justify-content-center d-flex px-2 w-50 container shadow p-3 mb-5 bg-white rounded'>
                    <div className='w-25 h-25 d-flex align-items-center border border-2 rounded-circle'>
                        <img src="static/images/pt.jpg" className="w-100" alt="" />
                    </div>
                    <div className='px-2 d-flex flex-column'>
                        <span>Name: Petrol Station 5</span>
                        <span>ID Number: 0000005</span>
                        <span>Location: E</span>

                    </div>
                    <div className='px-2 d-flex align-items-center'>
                        {
                            role === 'Manager' ? (
                                <NavLink to='/farmersec_mn' className='btn btn-primary'>
                                    View Detail
                                </NavLink>
                            ) : (
                                <NavLink to='/farmersec_gn' className='btn btn-primary'>
                                    View Detail
                                </NavLink>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    role: state.profile.role,
})

export default connect(mapStateToProps, {})(FarmerDetails)
