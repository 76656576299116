import React from 'react'
import { GoogleMap, useLoadScript, Marker } from "@googlemaps/react-wrapper"
import { Bar, Line, Pie, Doughnut } from "react-chartjs-2";
import { Chart, registerables, ArcElement, Tooltip, Legend } from 'chart.js';
import { BsSearch } from "react-icons/bs";
Chart.register(...registerables, ArcElement, Tooltip, Legend);

const Home = () => {

  const data = {
    labels: ["Jan 1", "Feb 1", "Mar 1", "Apr 1", "May 1", "Jun 1", "July 1", "Aug 1", "Sep 1", "Oct 1", "Nov 1", "Dec 1"],
    datasets: [
      {
        type: 'line',
        label: "First dataset",
        data: [90, 80, 100, 80, 50, 80, 130, 150, 100, 50, 100, 120],
        fill: false,
        backgroundColor: 'rgba( 0, 0, 0, 1)',
        borderColor: "rgba(0,0,0,1)",
        tension: "0.5",
        pointRadius: 0,
      },
      {
        type: 'bar',
        label: "Second dataset",
        data: [180, 80, 170, 140, 150, 180, 182, 60, 100, 250, 150, 160],
        fill: true,
        backgroundColor: 'rgba(59, 206, 132, 1)',
        borderColor: "rgba(59, 206, 132, 1)"

      },
    ]
  };

  const dataPie = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        label: "First dataset",
        data: [33, 53, 85, 41, 44, 65],
        fill: true,
        backgroundColor: ["#e60049", "#0bb4ff", "#50e991", "#e6d800", "#9b19f5", "#ffa300"],
        borderColor: "rgba(75,192,192,1)"
      },
    ]
  };

  const dataDoughnut = {
    label: ['Yes', 'No'],
    datasets: [{
      label: 'Poll',
      data: [10, 0],
      backgroundColor: ['blue', 'gray'],
      borderColor: ['blue', 'gray']
    }]
  }

  const dataDoughnut1 = {
    label: ['Yes', 'No'],
    datasets: [{
      label: 'Poll',
      data: [95, 5],
      backgroundColor: ['blue', 'grey'],
      borderColor: ['blue', 'grey']
    }]
  }

  const dataDoughnut2 = {
    label: ['Yes', 'No'],
    datasets: [{
      label: 'Poll',
      data: [4, 1],
      backgroundColor: ['blue', 'grey'],
      borderColor: ['blue', 'grey']
    }]
  }

  const dataDoughnut3 = {
    label: ['Yes', 'No'],
    datasets: [{
      label: 'Poll',
      data: [3, 2],
      backgroundColor: ['blue', 'grey'],
      borderColor: ['blue', 'grey']
    }]
  }

  const dataDoughnut4 = {
    label: ['Yes', 'No'],
    datasets: [{
      label: 'Poll',
      data: [5, 0],
      backgroundColor: ['blue', 'grey'],
      borderColor: ['blue', 'grey']
    }]
  }

  const optionsDoughnut = {
    maintainAspectRatio: false,
    cutout: 50
  }

  const optionsDoughnut1 = {
    maintainAspectRatio: false,
    cutout: 32
  }

  const textCenter = {
    id: 'textCenter',
    beforeDatasetsDraw(chart, args, pluginOptions) {
      const { ctx, data } = chart

      ctx.save()
      ctx.font = 'bolder 30px sans-serif'
      ctx.fillStyle = 'blue'
      ctx.textAlign = 'center'
      ctx.textBaseline = 'middle'
      ctx.fillText('AA', chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y)
    }
  }

  const textCenterDoughnut1 = {
    id: 'textCenter',
    beforeDatasetsDraw(chart, args, pluginOptions) {
      const { ctx, data } = chart

      ctx.save()
      ctx.font = 'bolder 20px sans-serif'
      ctx.fillStyle = 'blue'
      ctx.textAlign = 'center'
      ctx.textBaseline = 'middle'
      ctx.fillText(`${dataDoughnut1.datasets[0].data[0]}%`, chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y)
    }
  }

  const textCenterDoughnut2 = {
    id: 'textCenter',
    beforeDatasetsDraw(chart, args, pluginOptions) {
      const { ctx, data } = chart

      ctx.save()
      ctx.font = 'bolder 20px sans-serif'
      ctx.fillStyle = 'blue'
      ctx.textAlign = 'center'
      ctx.textBaseline = 'middle'
      ctx.fillText(`${dataDoughnut2.datasets[0].data[0]}/${dataDoughnut2.datasets[0].data[0] + dataDoughnut2.datasets[0].data[1]}`, chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y)
    }
  }

  const textCenterDoughnut3 = {
    id: 'textCenter',
    beforeDatasetsDraw(chart, args, pluginOptions) {
      const { ctx, data } = chart

      ctx.save()
      ctx.font = 'bolder 20px sans-serif'
      ctx.fillStyle = 'blue'
      ctx.textAlign = 'center'
      ctx.textBaseline = 'middle'
      ctx.fillText(`${dataDoughnut3.datasets[0].data[0]}/${dataDoughnut3.datasets[0].data[0] + dataDoughnut3.datasets[0].data[1]}`, chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y)
    }
  }

  const textCenterDoughnut4 = {
    id: 'textCenter',
    beforeDatasetsDraw(chart, args, pluginOptions) {
      const { ctx, data } = chart

      ctx.save()
      ctx.font = 'bolder 20px sans-serif'
      ctx.fillStyle = 'blue'
      ctx.textAlign = 'center'
      ctx.textBaseline = 'middle'
      ctx.fillText(`${dataDoughnut4.datasets[0].data[0]}/${dataDoughnut4.datasets[0].data[0] + dataDoughnut4.datasets[0].data[1]}`, chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y)
    }
  }

  const dataLine = {
    labels: ['2016', '2017', '2018', '2019', '2020', '2021', '2022'],
    datasets: [
      {
        label: 'Actual Reduction',
        data: [100000, 140000, 180000, 180000, 200000, 210000, 310000],
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
      },
      {
        label: 'Estimated Reduction',
        data: [120000, 140000, 180000, 210000, 240000, 270000, 300000],
        fill: false,
        borderColor: 'rgb(119, 10, 10)',
      }]
  };

  const optionsLine = {
    scales: {
      x: {
        title: {
          display: true,
          text: 'Year'
        }
      },
      y: {
        title: {
          display: true,
          text: 'Green House Gas Reduction (tCO2)'
        }
      }
    },
    plugins: {
      tooltip: {
        backgroundColor: "#fff",
        borderColor: "#ccc",
        borderWidth: 1,
        titleColor: "#000",
        bodyColor: "#000",
        bodyFont: {
          family: "Arial",
          size: 14,
          weight: "bold",
        },
        mode: "index",
        intersect: false,
      },
    },
  };

  const dataBar = {
    labels: ['2016', '2017', '2018', '2019', '2020', '2021', '2022'],
    datasets: [
      {
        label: 'Forest',
        data: [8000, 8000, 10000, 20000, 20000, 23000, 40000],
        backgroundColor: 'rgb(207, 110, 216)',
        stack: 'stack',
      },
      {
        label: 'Mangrove',
        data: [8000, 8000, 20000, 5000, 2500, 22000, 5000],
        backgroundColor: 'rgb(154, 94, 233)',
        stack: 'stack',
      },
      {
        label: 'Coral Reef',
        data: [3000, 4000, 10000, 5000, 2500, 20000, 35000],
        backgroundColor: 'rgb(27, 89, 248)',
        stack: 'stack',
      },
    ]
  };

  const optionsBar = {
    scales: {
      x: {
        title: {
          display: true,
          text: 'Year'
        }
      },
      y: {
        title: {
          display: true,
          text: 'Reforestation Progress (Ha)'
        }
      }
    },
  };

  const dataBar1 = {
    labels: ['2016', '2017', '2018', '2019', '2020', '2021', '2022'],
    datasets: [
      {
        label: 'Dataset 1',
        data: [65, 59, 80, 81, 56, 55, 40],
        backgroundColor: 'blue',
        stack: 'stack',
      },
    ]
  };

  const optionsBar1 = {
    scales: {
      x: {
        title: {
          display: true,
          text: 'Year'
        }
      },
      y: {
        title: {
          display: true,
          text: 'Fire Incident'
        }
      }
    },
  };

  return (
    <div className='container mt-4' >
      <div className='p-4'>
        <form className='row justify-content-around'>
          <label for="exampleFormControlSelect1" className='d-flex col-lg-1 align-items-center'>Timeframe:</label>
          <div className="form-group col-lg-3">
            <select className="form-control">
              <option>Sept 2022 - Oct 2022</option>
              <option>Oct 2022 - Nov 2022</option>
              <option>Nov 2022 - Dec 2022</option>
            </select>
          </div>
          <div className="form-group col-lg-3">
            <select className="form-control">
              <option value="" className='text-secondary'>Area</option>
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
          </div>
          <div className="form-group col-lg-3">
            <select className="form-control">
              <option value="" className='text-secondary'>Cadangan Kompleks IKS</option>
              <option>Cadangan Kompleks IKS</option>
              <option>Torong Akung Cafe</option>
              <option>Jeti Kampung Serusop</option>
              <option>Resort Eco Fishing</option>
              <option>SK Serusop</option>
            </select>
          </div>
          <div className='col-2 d-flex justify-content-center align-items-center'>
            <BsSearch />
          </div>
        </form>
      </div>
      <div className='p-4 row justify-content-around'>
        <div className='text-center col-lg-3 shadow p-3 mb-5 bg-body rounded' style={{ borderRadius: '4em' }}>
          <p>Number of On-going Projects</p>
          <h3 className='fw-bold'>10</h3>
        </div>
        <div className='text-center col-lg-3 shadow p-3 mb-5 bg-body rounded' style={{ borderRadius: '2em' }}>
          <p>Reforestation (Ha)</p>
          <h3 className='fw-bold'>20,000 Ha</h3>
        </div>
        <div className='text-center col-lg-3 shadow p-3 mb-5 bg-body rounded' style={{ borderRadius: '2em' }}>
          <p>Carbon Market Price (USD/T)</p>
          <h3 className='fw-bold'>$ 14.00/T</h3>
        </div>
      </div>
      <div className='mb-5' style={{ boxShadow: '5px 5px 10px 0px rgba(0, 0, 0, 0.2)', borderRadius: '2em', padding: '2em' }}>
        <h3 className='text-center'>Overall VERRA Rating</h3>
        <div style={{ width: '100%', height: '150px', padding: '10px' }} className="d-flex justify-content-center align-items-center">
          <div style={{ width: '30%', height: '100%' }}>
            <Doughnut
              data={dataDoughnut}
              options={optionsDoughnut}
              plugins={[textCenter]}
              width={'100%'}
              height={'100%'}
            ></Doughnut>
            <p className='fw-bold text-center'>Average VERRA Rating</p>
          </div>
          <div style={{ width: '15%', height: '70%' }}>
            <Doughnut
              data={dataDoughnut1}
              options={optionsDoughnut1}
              plugins={[textCenterDoughnut1]}
              width={'100%'}
              height={'100%'}
            ></Doughnut>
            <p className='fw-bold text-center'>Carbon Score</p>
          </div>
          <div style={{ width: '15%', height: '70%' }}>
            <Doughnut
              data={dataDoughnut2}
              options={optionsDoughnut1}
              plugins={[textCenterDoughnut2]}
              width={'100%'}
              height={'100%'}
            ></Doughnut>
            <p className='fw-bold text-center'>Additionality</p>
          </div>
          <div style={{ width: '15%', height: '70%' }}>
            <Doughnut
              data={dataDoughnut3}
              options={optionsDoughnut1}
              plugins={[textCenterDoughnut3]}
              width={'100%'}
              height={'100%'}
            ></Doughnut>
            <p className='fw-bold text-center'>Permanence</p>
          </div>
          <div style={{ width: '15%', height: '70%' }}>
            <Doughnut
              data={dataDoughnut4}
              options={optionsDoughnut1}
              plugins={[textCenterDoughnut4]}
              width={'100%'}
              height={'100%'}
            ></Doughnut>
            <p className='fw-bold text-center'>Co-benefits</p>
          </div>
        </div>
      </div>
      <div className='mb-5' style={{ boxShadow: '5px 5px 10px 0px rgba(0, 0, 0, 0.2)', borderRadius: '2em', padding: '2em' }}>
        <h4 className='fw-bold'>Green House Gas Reduction (tCO2)</h4>
        <Line data={dataLine} options={optionsLine}></Line>
      </div>
      <div className='mt-5' style={{ boxShadow: '5px 5px 10px 0px rgba(0, 0, 0, 0.2)', borderRadius: '2em', padding: '2em' }}>
        <h4 className='fw-bold'>Overall Reforestation Progress (Ha)</h4>
        <Bar data={dataBar} options={optionsBar}></Bar>
      </div>
      <div className='d-flex justify-content-around w-100 mt-5 mb-5'>
        <div className='w-45 p-3' style={{ overflow: 'auto', height: '400px' }}>
          <table className="table text-center">
            <thead>
              <tr>
                <th scope="col">No</th>
                <th scope="col">Project</th>
                <th scope="col">Activity</th>
                <th scope="col">Deadline</th>
                <th scope="col">Status</th>
                <th scope="col">Details</th>
              </tr>
            </thead>
            <tbody style={{ fontSize: '12px' }}>
              <tr >
                <th scope="row">1</th>
                <td>Pengerang, Johor</td>
                <td>Mangrove Planting</td>
                <td>08/11/22</td>
                <td className='text-center'><small className='bg-warning p-1 rounded-pill text-center'>Progress</small></td>
                <td><a href='#'>View</a></td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td>Pengerang, Johor</td>
                <td>Mangrove Planting</td>
                <td>08/11/22</td>
                <td className='text-center'><small className='bg-success p-1 rounded-pill text-center'>Completed</small></td>
                <td><a href='#'>View</a></td>
              </tr>
              <tr>
                <th scope="row">3</th>
                <td>Pengerang, Johor</td>
                <td>Mangrove Planting</td>
                <td>08/11/22</td>
                <td className='text-center'><small className='bg-secondary py-1 px-2 rounded-pill text-center'>Open</small></td>
                <td><a href='#'>View</a></td>
              </tr>
              <tr>
                <th scope="row">4</th>
                <td>Pengerang, Johor</td>
                <td>Mangrove Planting</td>
                <td>08/11/22</td>
                <td className='text-center'><small className='bg-secondary py-1 px-2 rounded-pill text-center'>Open</small></td>
                <td><a href='#'>View</a></td>
              </tr>
              <tr>
                <th scope="row">5</th>
                <td>Pengerang, Johor</td>
                <td>Mangrove Planting</td>
                <td>08/11/22</td>
                <td className='text-center'><small className='bg-secondary py-1 px-2 rounded-pill text-center'>Open</small></td>
                <td><a href='#'>View</a></td>
              </tr>
              <tr>
                <th scope="row">6</th>
                <td>Pengerang, Johor</td>
                <td>Mangrove Planting</td>
                <td>08/11/22</td>
                <td className='text-center'><small className='bg-secondary py-1 px-2 rounded-pill text-center'>Open</small></td>
                <td><a href='#'>View</a></td>
              </tr>
              <tr>
                <th scope="row">7</th>
                <td>Pengerang, Johor</td>
                <td>Mangrove Planting</td>
                <td>08/11/22</td>
                <td className='text-center'><small className='bg-secondary py-1 px-2 rounded-pill text-center'>Open</small></td>
                <td><a href='#'>View</a></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className='w-50' style={{ boxShadow: '5px 5px 10px 0px rgba(0, 0, 0, 0.2)', borderRadius: '2em', padding: '2em' }}>
          <h4 className='fw-bold text-center'>Fire Analysis</h4>
          <Bar data={dataBar1} options={optionsBar1}></Bar>
        </div>
      </div>
      <div className='p-4 row'>
        <div className='border rounded col-lg shadow-sm p-3'>
          <img src="static/images/Weather Graph.png" alt="" className='w-100' />
        </div>
      </div>
    </div >
  )
}

export default Home