import React, { useState } from 'react'
import { Link, Navigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { login } from '../actions/auth'
import Container from 'react-bootstrap/Container'
import './containers.css'
import { ToastContainer } from 'react-toastify'
import '../../node_modules/react-toastify/dist/ReactToastify.css'



const Login = ({ login, isAuthenticated, role }) => {
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    })

    const { email, password } = formData

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })
    const onSubmit = e => {
        e.preventDefault()

        login(email, password)
    }

    if (isAuthenticated && role === 'Manager') {
        return <Navigate to='/home_mn' />
    } else if (isAuthenticated && role === 'Clerk') {
        return <Navigate to='/home_gn' />
    }

    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div id="main-login-cont" className='h-100 d-flex justify-content-center'>
                <div id="sec-login-cont" className='p-5 w-25 h-50 d-flex justify-content-center align-items-center' style={{ background: '#ffffff' }}>
                    <div>
                        <div className='d-flex justify-content-center w-100'>
                            <img src="/static/images/pt.jpg" alt="" className='w-50' />
                        </div>
                        <form onSubmit={e => onSubmit(e)} className="text-center">
                            <div className='form-group mt-4'>
                                <input type="email" className='form-control' placeholder='Email' name='email' value={email} onChange={e => onChange(e)} required />
                            </div>
                            <div className='form-group mt-4'>
                                <input type="password" className='form-control' placeholder='Password' name='password' value={password} onChange={e => onChange(e)} minLength='6' required />
                            </div>
                            <button className='btn-login mt-4 w-100' type='submit'>Login</button>
                        </form>
                    </div>
                </div>
                <div style={{ background: '#525252' }} className='w-25 h-50 info-login'>
                    <h3>Welcome to our Demo!</h3>
                    <p>Please test the web application as you see fit and tell us your feedback</p>
                    <p>Your feedback is appreciated and help us to improve the solution further.</p>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    role: state.profile.role
})

export default connect(mapStateToProps, { login })(Login)