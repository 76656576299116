import React from 'react'
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    Navigate
} from "react-router-dom";
import { Provider } from 'react-redux';
import store from './store'

import Home from './containers/Home';
import Login from './containers/Login';
import Account from './containers/Account';
import Layout from './hocs/Layout';
import FarmerDetails from './containers/FarmerDetails';
import FarmerDetailsSec from './containers/FarmerDetailsSec';
import Inventory from './containers/Inventory';
import PlantProgress from './containers/PlantProgress';
import Repository from './containers/Repository';
import Report from './containers/Report';
import PlantProgressDetails from './containers/PlantProgressDetails';
import Summary from './containers/Summary';
import Summary1 from './containers/Summary1';
import Navbar from './components/Navbar';
import PrivateRouteAdmin from './hocs/PrivateRouteAdmin'
import PrivateRouteManager from './hocs/PrivateRouteManager'
import './app.css'
import 'leaflet/dist/leaflet.css';


const App = () => (
    <Provider store={store}>
        <Router>
            <Routes>
                <Route exact path="/" element={<Login />} />
            </Routes>
            <Layout>
                <Routes>
                    {/* <Route exact path="/home" element={<Home />} /> */}
                    <Route exact path="/home_gn" element={
                        <PrivateRouteAdmin>
                            <Home />
                        </PrivateRouteAdmin>
                    } />
                    <Route exact path="/home_mn" element={
                        <PrivateRouteManager>
                            <Home />
                        </PrivateRouteManager>
                    } />

                    {/* <Route exact path="/account" element={<Account />} /> */}
                    <Route exact path="/account_gn" element={
                        <PrivateRouteAdmin>
                            <Account />
                        </PrivateRouteAdmin>
                    } />
                    <Route exact path="/account_mn" element={
                        <PrivateRouteManager>
                            <Account />
                        </PrivateRouteManager>
                    } />

                    {/* <Route exact path="/farmer" element={<FarmerDetails />} /> */}
                    <Route exact path="/farmer_gn" element={
                        <PrivateRouteAdmin>
                            <FarmerDetails />
                        </PrivateRouteAdmin>
                    } />
                    <Route exact path="/farmer_mn" element={
                        <PrivateRouteManager>
                            <FarmerDetails />
                        </PrivateRouteManager>
                    } />

                    {/* <Route exact path="/farmersec" element={<FarmerDetailsSec />} /> */}
                    <Route exact path="/farmersec_gn" element={
                        <PrivateRouteAdmin>
                            <FarmerDetailsSec />
                        </PrivateRouteAdmin>
                    } />
                    <Route exact path="/farmersec_mn" element={
                        <PrivateRouteManager>
                            <FarmerDetailsSec />
                        </PrivateRouteManager>
                    } />

                    {/* <Route exact path="/inventory" element={<Inventory />} /> */}
                    <Route exact path="/inventory_gn" element={
                        <PrivateRouteAdmin>
                            <Inventory />
                        </PrivateRouteAdmin>
                    } />
                    <Route exact path="/inventory_mn" element={
                        <PrivateRouteManager>
                            <Inventory />
                        </PrivateRouteManager>
                    } />

                    {/* <Route exact path="/plantprogress" element={<PlantProgress />} /> */}
                    <Route exact path="/plantprogress_gn" element={
                        <PrivateRouteAdmin>
                            <PlantProgress />
                        </PrivateRouteAdmin>
                    } />
                    <Route exact path="/plantprogress_mn" element={
                        <PrivateRouteManager>
                            <PlantProgress />
                        </PrivateRouteManager>
                    } />
                    <Route exact path="/plantprogressdetails_gn" element={
                        <PrivateRouteAdmin>
                            <PlantProgressDetails />
                        </PrivateRouteAdmin>
                    } />
                    <Route exact path="/plantprogressdetails_mn" element={
                        <PrivateRouteManager>
                            <PlantProgressDetails />
                        </PrivateRouteManager>
                    } />

                    {/* <Route exact path="/repository" element={<Repository />} /> */}
                    <Route exact path="/repository_gn" element={
                        <PrivateRouteAdmin>
                            <Repository />
                        </PrivateRouteAdmin>
                    } />
                    <Route exact path="/repository_mn" element={
                        <PrivateRouteManager>
                            <Repository />
                        </PrivateRouteManager>
                    } />

                    {/* <Route exact path="/summary_view" element={<Summary1 />} /> */}
                    <Route exact path="/summary_view_gn" element={
                        <PrivateRouteAdmin>
                            <Summary1 />
                        </PrivateRouteAdmin>
                    } />
                    <Route exact path="/summary_view_mn" element={
                        <PrivateRouteManager>
                            <Summary1 />
                        </PrivateRouteManager>
                    } />


                    <Route exact path="/report_gn" element={
                        <PrivateRouteAdmin>
                            <Report />
                        </PrivateRouteAdmin>
                    } />
                    <Route exact path="/report_mn" element={
                        <PrivateRouteManager>
                            <Report />
                        </PrivateRouteManager>
                    } />

                    {/* <Route exact path="/plantprogressdetails" element={<PlantProgressDetails />} /> */}
                    <Route exact path="/plantprogressdetails_gn" element={
                        <PrivateRouteAdmin>
                            <PlantProgressDetails />
                        </PrivateRouteAdmin>
                    } />
                    <Route exact path="/plantprogressdetails_mn" element={
                        <PrivateRouteManager>
                            <PlantProgressDetails />
                        </PrivateRouteManager>
                    } />

                    {/* <Route exact path="/summary" element={<Summary />} /> */}
                    <Route exact path="/summary_gn" element={
                        <PrivateRouteAdmin>
                            <Summary />
                        </PrivateRouteAdmin>
                    } />
                    <Route exact path="/summary_mn" element={
                        <PrivateRouteManager>
                            <Summary />
                        </PrivateRouteManager>
                    } />

                    <Route render={() => <Navigate to="/" />} />
                </Routes>
            </Layout>
        </Router>
    </Provider>

);

export default App